import Link from "next/link";
import {addSlashIfNotAbsoluteUrl} from "../../../utils/Helpers";
import classNames from "classnames";
import Image from "next/image";
import {Disclosure} from "@headlessui/react";

export default function FlyoutLinksMobile(props: { items: any[]; isInverted: any; }) {
    return <>{props.items.map((item: any) => (
        <Disclosure.Button
            as={Link}
            key={item.name}
            href={item.link.cached_url ? addSlashIfNotAbsoluteUrl(item.link.cached_url) : item.link}
            className={classNames(
                "-m-3 flex items-center rounded-lg p-3 transition duration-150 ease-in-out",
                props.isInverted ? 'hover:bg-gray-700' : 'hover:bg-gray-50'
            )}
        >
            {item.icon &&
                <span
                    className="h-7 w-7 flex-shrink-0 text-secondary"
                    dangerouslySetInnerHTML={{
                        __html: (item.icon)
                    }}
                />}
            {item.alt_image && item.alt_image.filename !== '' &&
                <div className={'w-7 h-7 flex-shrink-0 relative'}><Image loading={'lazy'} src={item.alt_image.filename} fill={true}
                                                 className={"object-contain object-center"} alt={item.name}/></div>}

            <div className="ml-4">
                <p className={classNames(
                    "text-base font-medium",
                    props.isInverted ? 'text-white' : 'text-gray-900'
                )}>{item.name}</p>
                <p className={classNames(
                    "mt-1 text-xs",
                    props.isInverted ? 'text-gray-300' : 'text-gray-500'
                )}>{item.description}</p>
            </div>
        </Disclosure.Button>
    ))}</>
}
import dynamic from "next/dynamic";

export const runtime = "edge";
import Head from 'next/head'
import {PropsWithChildren, useEffect} from "react";

// const Navbar = dynamic(() => import("./partials/Navbar"), {
//     loading: () => <p>Loading...</p>,
// });

import Navbar from "./partials/Navbar";

// import Footer from "./partials/Footer";
import Footer from './partials/Footer';


import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {ArrowRightIcon} from "@heroicons/react/20/solid";
import NavbarLanding from "./partials/NavbarLanding";
import { Analytics } from '@vercel/analytics/react';
// @ts-ignore
// import AOS from 'aos';
// import 'aos/dist/aos.css';

export default function BaseLayout(props: any) {

    const contactCTA = <a className={'link flex items-center'}>Get a free consultation <ArrowRightIcon
        className={'h-4 w-4 ml-1'}/></a>;

    const messages = [
        () => <div>
            A client from Germany described us as easy to work with and professional.
            <br/>
            {contactCTA}
        </div>,
        () => <div>
            One client said we have great ideas and commitment.
            <br/>
            {contactCTA}
        </div>,
        () => <div>
            We were described as uncomplicated and familiar.
            <br/>
            {contactCTA}
        </div>,
        () => <div>
            A client recommended as us a good agency for high-quality and complex developments.
            <br/>
            {contactCTA}
        </div>,
        () => <div>
            A client described the cooperation easy and professional.
            <br/>
            {contactCTA}
        </div>,
        () => <div>
            A client described us as a very good agency for high-quality and complex developments.
            <br/>
            {contactCTA}
        </div>,
    ]

    useEffect(() => {
        let interval: any;


        // if( navigator.userAgent.match(/(Mozilla\/5\.0 \(Linux; Android 11; moto g power \(2022\)\) AppleWebKit\/537\.36 \(KHTML, like Gecko\) Chrome\/109\.0.0.0 Mobile Safari\/537\.36)|(Mozilla\/5\.0 \(Macintosh; Intel Mac OS X 10_15_7\) AppleWebKit\/537\.36 \(KHTML, like Gecko\) Chrome\/109\.0\.0\.0 Safari\/537\.36)|(Speed Insights)|(Chrome-Lighthouse)|(PSTS[\d\.]+)/) ) { } else {

        if (typeof window !== 'undefined') {
            // If screen greater than 480 px
            if (window.innerWidth > 480) {
                // Add AOS from CDN
                const aosScript = document.createElement('script');
                aosScript.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/aos/2.3.4/aos.js');
                aosScript.onload = () => {
                    // Check if next is in dev env
                    if (process.env.NODE_ENV === 'development') {
                        setTimeout(() => {
                            // @ts-ignore
                            AOS.init({disable: 'mobile'}); // Don't throw that className error
                        }, 500)
                    } else {
                        // @ts-ignore
                        AOS.init({disable: 'mobile'});
                    }
                }

                document.head.appendChild(aosScript);
                // Add AOS css
                const aosCss = document.createElement('link');
                aosCss.setAttribute('rel', 'stylesheet');
                aosCss.setAttribute('href', 'https://cdnjs.cloudflare.com/ajax/libs/aos/2.3.4/aos.css');
                document.head.appendChild(aosCss);
            }

            // if production
            if (process.env.NODE_ENV === 'production') {
                // // Add Hubspot Tracking <!-- Start of HubSpot Embed Code -->
                // const hubspotScript = document.createElement('script');
                // hubspotScript.setAttribute('type', 'text/javascript');
                // hubspotScript.setAttribute('id', 'hs-script-loader');
                // hubspotScript.setAttribute('async', '');
                // hubspotScript.setAttribute('defer', '');
                // hubspotScript.setAttribute('src', '//js-eu1.hs-scripts.com/25424578.js');
                // document.head.appendChild(hubspotScript);

                // const gaScript = document.createElement('script');
                // gaScript.setAttribute('type', 'text/javascript');
                // gaScript.setAttribute('async', '');
                // gaScript.setAttribute('defer', '');
                // gaScript.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=G-SCBV88J37X');
                // gaScript.onload = () => {
                //     // @ts-ignore
                //     window.dataLayer = window.dataLayer || [];
                //     // @ts-ignore
                //     function gtag(){dataLayer.push(arguments);}
                //     // @ts-ignore
                //     gtag('js', new Date());
                //
                //     // @ts-ignore
                //     gtag('config', 'G-SCBV88J37X');
                //     // @ts-ignore
                //     gtag('config', 'AW-10838573329');
                // }
                // document.head.appendChild(gaScript);

                // if (props.isLandingPage) {
                    // Add Hotjar script
                    // (function (h, o, t, j, a, r) {
                    //     // @ts-ignore
                    //     h.hj = h.hj || function () {
                    //         // @ts-ignore
                    //         (h.hj.q = h.hj.q || []).push(arguments)
                    //     };
                    //     // @ts-ignore
                    //     h._hjSettings = {hjid: 2872654, hjsv: 6};
                    //     // @ts-ignore
                    //     a = o.getElementsByTagName('head')[0];
                    //     // @ts-ignore
                    //     r = o.createElement('script');
                    //     // @ts-ignore
                    //     r.async = 1;
                    //     // @ts-ignore
                    //     r.defer = 1;
                    //     // @ts-ignore
                    //     r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
                    //     // @ts-ignore
                    //     a.appendChild(r);
                    // })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
                // }
            }

        }

        // interval = setInterval(() => {
        //     // Only show toast if window is focused
        //     if (typeof window !== 'undefined' && !window.document.hasFocus()) {
        //
        //         // Randomize messages array
        //         messages.sort(() => Math.random() - 0.5);
        //
        //         toast(messages[0], {
        //             type: 'success',
        //             position: 'bottom-left',
        //             autoClose: 15000,
        //             pauseOnFocusLoss: false
        //         });
        //     }
        // }, 30000);
        //
        // return () => {
        //     clearInterval(interval);
        // }
    }, [])

    return <>
        <Head>
            <meta name="viewport" content="width=device-width, initial-scale=1"/>
            {/*<link rel="preconnect" href="https://www.googletagmanager.com/gtag/js?id=AW-10838573329"/>*/}
            {/*<link rel="preconnect" href="https://fonts.googleapis.com"/>*/}
            {/*<link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin={"crossOrigin"}/>*/}

        </Head>

        <Analytics/>
        {/*<SpeedInsights/>*/}

        <ToastContainer theme={'light'}/>

        {!props.isLandingPage &&
            <Navbar
                navigation={props.navigation}
                invert={props.invert === true}
            />}

        {props.isLandingPage &&
            <NavbarLanding
                invert={props.invert === true}
            />}

        {/*<Suspense fallback={<div>Loading...</div>}>*/}

        {props.children}

        {/*</Suspense>*/}

        <Footer footer={props.footer} isLandingPage={props.isLandingPage}/>
    </>
}
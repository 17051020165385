import {Fragment, useEffect, useState} from 'react'
import {Disclosure} from '@headlessui/react'
import {Bars3Icon, BellIcon, XMarkIcon, EnvelopeIcon} from '@heroicons/react/24/outline'
import {useRouter} from "next/router";
import Flyout from "./Flyout";
import Link from "next/link";
import React from 'react';
import classNames from "classnames";
import ButtonGroup from "../../partials/ButtonGroup";
import ScheduleAppointment from "../../functional/ScheduleAppointment";
import {addSlashIfNotAbsoluteUrl, triggerPhoneConversion} from "../../../utils/Helpers";
import {PhoneIcon} from "@heroicons/react/24/solid";
import Image from "next/image";
import FlyoutMobile from "./FlyoutMobile";


export default function Navbar(props: { navigation: any, invert: boolean }) {
    const router = useRouter();

    const [isFixed, setIsFixed] = useState(false);
    const [isInverted, setIsInverted] = useState(props.invert);
    const [isScrollingDown, setIsScrollingDown] = useState(false);
    const [lastScrollTop, setLastScrollTop] = useState(0);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const handleRouteChange = (url: URL) => {
            setIsInverted(props.invert);
            setOpen(false)
        };

        router.events.on("routeChangeComplete", handleRouteChange);
        return () => {
            router.events.off("routeChangeComplete", handleRouteChange);
        };



    }, [router.events])

    useEffect(() => {
        setIsInverted(props.invert)

        if (open) {
            setIsInverted(false)
        }
    }, [open])

    // Set isFixed to true when the user scrolls down 50px from the top of the document

    const handleScroll = () => {
        if (open) {
            return;
        }

        const st = window.scrollY;

        if (st > 100) {
            setIsScrollingDown(st > lastScrollTop);
        }
        setLastScrollTop(st);

        if (window.scrollY > 50) {
            setIsFixed(true);
            setIsInverted(false)
        } else {
            setIsFixed(false);
            setIsInverted(props.invert)
        }
    }

    useEffect(() => {
        window.onscroll = handleScroll;

        return () => {
            window.onscroll = null;
        }
    })


    const isActive = (el: any) => {
        if (router.asPath === '/' && el.link.cached_url === 'home') {
            return true;
        }

        return "/" + el.link.cached_url == router.asPath
    }

    // const startButton = <button type="button"
    //                             className="inline-flex items-center rounded-full border border-transparent bg-primary px-5 py-2 text-base font-medium text-white shadow-sm hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
    //     <EnvelopeIcon className={'w-5 h-5 mr-1'}/>
    //     Start your Next Project
    // </button>

    const startButton = <ButtonGroup key={'navbarbuttons'} buttons={[{
        'title': 'We Respond within 24 Hours',
        'special_action': 'form',
        'class': isInverted ? 'btn-white' : 'btn-secondary',
        'icon': "<svg xmlns=\"http://www.w3.org/2000/svg\" fill=\"none\" viewBox=\"0 0 24 24\" stroke-width=\"1.5\" stroke=\"currentColor\" class=\"ml-1 w-5 h-5\">\n" +
            "  <path stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z\" />\n" +
            "</svg>\n"
    }]}/>

    const logoComponent = (open: boolean) => {
        return <>
            {(isInverted && !open) && <>
                <Image
                    fill={true}
                    loading={'eager'}
                    className="block h-8 w-auto lg:hidden "
                    src="/images/SVG/logo_white.svg"
                    alt="Pacific Codeline LLC Logo"
                />
                <Image
                    fill={true}
                    loading={'eager'}
                    className="hidden h-12 w-auto lg:block mt-2"
                    src="/images/SVG/logo_white.svg"
                    alt="Pacific Codeline LLC Logo"
                />
            </>}
            {(!isInverted || open) && <>
                <Image
                    fill={true}
                    loading={'eager'}
                    className="block h-8 w-auto lg:hidden "
                    src="/images/SVG/logo.svg"
                    alt="Pacific Codeline LLC Logo"
                />
                <Image
                    fill={true}
                    loading={'eager'}
                    className="hidden h-12 w-auto lg:block mt-2"
                    src="/images/SVG/logo.svg"
                    alt="Pacific Codeline LLC Logo"
                />
            </>}
        </>
    }

    return (
        <React.Fragment>
            <div className={'py-10 sm:py-16 lg:py-18 xl:py-14'}/>

            <Disclosure as="nav" className={
                classNames(
                    {
                        "z-50 bg-transparent fixed top-0 w-full text-white xl:pt-3 xl:pb-3 transition-colors transition-opacity duration-500": true,
                        'bg-white shadow': isFixed,
                        'opacity-0 sm:opacity-100': isScrollingDown && !open,
                    },
                    // isInverted ? 'bg-gray-700' : 'bg-transparent',
                    isFixed ? 'bg-warm-gray-50' : '',
                    open ? ' h-full md:h-auto' : ''
                )
            }>
                {({open}) => {

                    if (typeof document !== 'undefined') {
                        if (open) {
                            document.querySelector('body')?.classList?.add('overflow-hidden');
                        } else {
                            document.querySelector('body')?.classList?.remove('overflow-hidden');
                        }
                    }

                    setOpen(open)

                    return <>
                        <div className={classNames(
                            'p-3 justify-space-between items-end hidden sm:flex xl:hidden',
                            (!open && isInverted) ? 'bg-gray-900' : 'bg-warm-gray-100'
                        )}>
                            <div className={'md:hidden aspect-[1/.17] relative'}>
                                {logoComponent(open)}
                            </div>

                            <div className={'hidden md:flex mr-auto'}>

                                <a className={'btn btn-white-xs'} href={'tel:+19495412305'}
                                   onClick={() => triggerPhoneConversion()}>
                                    <PhoneIcon className={'w-4 h-4 mr-1'}/>
                                    Call Today: (949) 541-2305
                                </a>
                            </div>

                            <ScheduleAppointment
                                buttonElement={{
                                    'title': 'Schedule a Consultation',
                                    'icon': "<svg xmlns=\"http://www.w3.org/2000/svg\" fill=\"none\" viewBox=\"0 0 24 24\" stroke-width=\"1.5\" stroke=\"currentColor\" class=\"ml-1 w-5 h-5\">\n" +
                                        "  <path stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z\" />\n" +
                                        "</svg>\n"
                                }}
                                classNames="inline-flex ml-auto items-center rounded-md border border-transparent bg-primary px-5 py-1 text-sm font-medium text-white shadow-sm hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-amber-500 focus:ring-offset-2">

                            </ScheduleAppointment>
                        </div>

                        <div
                            className={classNames(
                                "mx-auto max-w-screen-2xl px-4 sm:px-4 lg:px-8 py-2 transition-colors transition-opacity duration-500",
                                ((open) ? 'bg-white' : ''),
                                // isInverted ? 'bg-gray-700' : 'bg-transparent'
                            )}>

                            <div className="relative flex h-16 justify-between items-center">
                                <div className="absolute inset-y-0 right-0 flex items-center sm:hidden">
                                    {/* Mobile menu button */}
                                    <Disclosure.Button
                                        className={classNames(
                                            `inline-flex items-center justify-center rounded-md p-2
                                             
                                            focus:outline-none focus:ring-1 focus:ring-inset focus:ring-amber-500`,
                                            (!open && isInverted) ? 'text-white' : 'text-gray-700'
                                        )}>
                                        <span className="sr-only">Open main menu</span>
                                        {open ? (
                                            <XMarkIcon className="block h-8 w-8" aria-hidden="true"/>
                                        ) : (
                                            <Bars3Icon className="block h-8 w-8" aria-hidden="true"/>
                                        )}
                                    </Disclosure.Button>
                                </div>

                                <div
                                    className="flex flex-1 items-center justify-start sm:items-stretch sm:justify-start ">
                                    <div className="flex sm:hidden md:flex flex-shrink-0 items-center sm:mt-3 lg:mt-0 ">
                                        <Link href={"/"} className={'aspect-[1/.17] block h-8 lg:h-12 relative'}>
                                            {logoComponent(open)}
                                        </Link>
                                    </div>
                                    <div
                                        className="hidden mx-auto md:mr-0 md:ml-auto xl:mx-auto sm:flex sm:space-x-2 lg:space-x-6">
                                        {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                                        {props?.navigation?.content?.items.map((el: any) => {
                                            if (el.component === 'NavigationItem') {
                                                return (
                                                    <Link
                                                        key={"key1"+el._uid}
                                                        href={addSlashIfNotAbsoluteUrl(el.link.cached_url)}
                                                        className={
                                                            classNames(
                                                                `inline-flex items-center border-t-2 px-1 pt-1 xl:text-md uppercase transition-all duration-500`,
                                                                isActive(el) ? 'border-primary' : 'border-transparent',
                                                                isActive(el) ? 'font-bold' : 'font-light',
                                                                isInverted ? 'text-white hover:text-gray-100' : 'text-black hover:text-gray-400'
                                                            )
                                                        }
                                                    >
                                                        {el.name}
                                                    </Link>
                                                )
                                            } else if (el.component === 'FlyoutItem') {
                                                return (
                                                    <>
                                                        <Flyout
                                                            className={'hidden md:block'}
                                                            isInverted={isInverted}
                                                            key={el._uid}
                                                            item={el}>
                                                <span
                                                    className={classNames(
                                                        'transition-all duration-500',
                                                        isInverted ? 'text-white hover:text-gray-100' : 'text-black hover:text-gray-400'
                                                    )}
                                                >
                                                    {el.name}
                                                </span>
                                                        </Flyout>

                                                    </>
                                                )
                                            }
                                        })}

                                    </div>
                                </div>
                                <div
                                    className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0
                                    hidden xl:block
                                ">

                                    {startButton}

                                </div>
                            </div>
                        </div>

                        {/* Mobile menu */}
                        <Disclosure.Panel className="sm:hidden">
                            <div
                                key={'mobilemenu'}
                                className={classNames(
                                "space-y-1 pt-2 h-[calc(100vh-78px)] overflow-y-scroll bg-white fixed top-[78px] pb-[80px]",
                            )}>
                                {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}


                                {props?.navigation?.content?.items.map((el: any) => {
                                    if (el.component === 'NavigationItem') {
                                        return (
                                            <Disclosure.Button
                                                as="a"
                                                key={"key"+el._uid}
                                                href={addSlashIfNotAbsoluteUrl(el.link.cached_url)}
                                                className={
                                                    classNames(
                                                        `block text-lg uppercase border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium`,
                                                        isActive(el) ? 'border-amber-500 border-primary bg-amber-50' : 'border-transparent',
                                                        isActive(el) ? 'font-bold' : 'font-light',
                                                        (false && isInverted) ? 'text-white hover:text-gray-100' : 'text-black hover:text-gray-400'
                                                    )
                                                }
                                            >
                                                {el.name}
                                            </Disclosure.Button>
                                        )
                                    } else if (el.component === 'FlyoutItem') {
                                        return (
                                            <div key={el._uid} className={'pb-2'}>
                                                <FlyoutMobile
                                                    key={"flyout"+el._uid}
                                                    item={el}
                                                    className={'md:hidden'}>
                                                               <span
                                                                   className={classNames(
                                                                       'transition-all duration-500',
                                                                       isInverted ? 'text-white hover:text-gray-100' : 'text-black hover:text-gray-400'
                                                                   )}
                                                               >
                                                    {el.name}
                                                </span>
                                                </FlyoutMobile>
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                        </Disclosure.Panel>
                    </>
                }
                }
            </Disclosure>
        </React.Fragment>
    )
}
/* @ts-nocheck */
import {Fragment, useEffect, useState} from 'react'
import {Popover, Transition} from '@headlessui/react'
import {ChevronDownIcon, ChevronUpIcon} from '@heroicons/react/20/solid'
import {
    PhoneIcon,
    ChatBubbleLeftIcon,
} from '@heroicons/react/24/outline'
import {useRouter} from "next/router";
import classNames from "classnames";
import {addSlashIfNotAbsoluteUrl} from "../../../utils/Helpers";
import FlyoutLinks from "./FlyoutLinks";

const callsToAction = [
    {name: 'Call', href: 'tel:+19495412305', icon: PhoneIcon},
    {name: 'Contact Us', href: '/contact', icon: ChatBubbleLeftIcon},
]

export default function Flyout(props: any) {
    const [isShowing, setIsShowing] = useState(false)
    const [isActive, setIsActive] = useState(false)
    const {item: {items}} = props;

    let isInverted = props.isInverted;

    const router = useRouter();

    const highlightIfChildrenIsActivePath = (items: any) => {
        items.map((el: { link: { cached_url: string; }; }) => {
            if ("/" + el.link.cached_url === router.asPath) {
                setIsActive(true)
            }
        })
    }

    useEffect(() => {
        highlightIfChildrenIsActivePath((items))
    }, [items])

    let timeout: any = null;

    const setDebouncedIsShowing = (value: boolean, delay: number) => {
        clearTimeout(timeout)

        timeout = setTimeout(() => {
            setIsShowing(value)
        }, delay);
    }

    return (
        <Popover
            className={classNames("inline-flex items-center md:border-b-2 px-1 pt-2 text-md font-normal text-gray-900",
                isShowing || isActive ? 'border-primary' : 'border-transparent')}>
            {/*{({ open }) => (*/}
            <div className={'relative'}>

                <Popover.Button
                    onMouseEnter={() => setDebouncedIsShowing(true, 0)}
                    onMouseLeave={() => setDebouncedIsShowing(false, 300)}
                    onClickCapture={() => setDebouncedIsShowing(!isShowing, 0)}
                    className={classNames(
                        isShowing ? 'text-gray-700' : 'text-black',
                        'inline-flex items-center xl:text-md',
                        isActive ? 'font-bold' : 'font-light',
                        'focus:ring-0'
                    )}
                >
                        <span className={'uppercase'}>
                            {props.children}
                        </span>
                    {!isShowing &&
                        <ChevronDownIcon
                            className={classNames(isInverted ? 'text-white' : 'text-gray-400', 'ml-2 h-5 w-5 group-hover:text-gray-500')}
                            aria-hidden="true"
                        />
                    }
                    {isShowing &&
                        <ChevronUpIcon
                            className={classNames(
                                isInverted ? 'text-white' : 'text-gray-600',
                                'ml-2 h-5 w-5 group-hover:text-gray-500'
                            )}
                            aria-hidden="true"
                        />
                    }
                </Popover.Button>

                <Transition
                    show={isShowing}
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                >
                    <Popover.Panel
                        onMouseEnter={() => setDebouncedIsShowing(true, 0)}
                        onMouseLeave={() => setDebouncedIsShowing(false, 300)}
                        className={classNames(
                            "absolute left-0 md:left-1/2 z-30 mt-3 w-screen max-w-[950px] -translate-x-1 md:-translate-x-1/2 transform px-4 pb-4 sm:px-0"
                        )}>
                        <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                            <div className={classNames('grid md:grid-cols-9 px-5 py-5 sm:space-x-10', isInverted ? 'bg-gray-600' : ' bg-white')}>
                                <div className={'col-span-3'}>
                                    <h2 className={classNames('text-lg font-light mb-3', isInverted ? 'text-white' : '')}>Services</h2>
                                    <div className={classNames(
                                        "relative grid gap-4 sm:gap:4",
                                        isInverted ? 'bg-gray-600' : 'bg-white'
                                    )}>
                                        <FlyoutLinks items={items} isInverted={isInverted}/>
                                    </div>
                                </div>
                                <div className={'col-span-3 mt-5 md:mt-0'}>
                                    <h2 className={classNames('text-lg font-light mb-3', isInverted ? 'text-white' : '')}>Technologies</h2>

                                    <div className={classNames(
                                        "relative grid gap-4 sm:gap:4",
                                        isInverted ? 'bg-gray-600' : 'bg-white'
                                    )}>
                                        <FlyoutLinks items={props.item.itemscol2} isInverted={isInverted}/>
                                    </div>
                                </div>
                                <div className={'col-span-3 mt-5 md:mt-0'}>
                                    <h2 className={classNames('text-lg font-light mb-3', isInverted ? 'text-white' : '')}>More</h2>

                                    <div className={classNames(
                                        "relative grid gap-4 sm:gap:4",
                                        isInverted ? 'bg-gray-600' : 'bg-white'
                                    )}>
                                        <FlyoutLinks items={props.item.itemscol3} isInverted={isInverted}/>
                                    </div>
                                </div>
                            </div>
                            <div className="space-y-6 bg-gray-50 sm:flex sm:space-y-0 px-5 py-5 flex gap-8">
                                {callsToAction.map((item) => (
                                    <div key={item.name} className="flow-root">
                                        <a
                                            href={item.href}
                                            className="-m-3 flex items-center rounded-md p-3 text-sm font-normal text-gray-900 transition duration-150 ease-in-out hover:bg-gray-100"
                                        >
                                            <item.icon className="h-6 w-6 flex-shrink-0 text-gray-400"
                                                       aria-hidden="true"/>
                                            <span className="ml-2">{item.name}</span>
                                        </a>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Popover.Panel>
                </Transition>
            </div>
            {/*)}*/}
        </Popover>
    )
}

/* @ts-nocheck */
import {Disclosure} from '@headlessui/react'

import {useRouter} from "next/router";
import classNames from "classnames";
import FlyoutLinksMobile from "./FlyoutLinksMobile";

export default function FlyoutMobile(props: any) {
    const {item: {items}} = props;

    let isInverted = props.isInverted;

    const router = useRouter();
    return (
        <div
        >

            <div className={'relative'}>

                <div
                >
                    <div className="overflow-hidden">
                        <div
                            className={classNames('grid md:grid-cols-9 sm:space-x-2', isInverted ? 'bg-gray-600' : ' bg-white')}>
                            <div className={'col-span-3'}>

                                <a

                                    className={
                                        classNames(
                                            `block text-lg uppercase border-l-4 border-transparent py-2 pl-3 pr-4 font-medium`,
                                            (false && isInverted) ? 'text-white hover:text-gray-100' : 'text-black hover:text-gray-400'
                                        )
                                    }
                                >
                    <span className={'uppercase'}>
                            Services
                        </span>
                                </a>

                                <div className={classNames(
                                    "relative grid gap-2 sm:gap:4 px-4",
                                    isInverted ? 'bg-gray-600' : 'bg-white'
                                )}>
                                    <FlyoutLinksMobile items={items} isInverted={isInverted}/>
                                </div>
                            </div>
                            <div className={'col-span-3 mt-5 md:mt-0'}>
                                <a

                                    className={
                                        classNames(
                                            `block text-lg uppercase border-l-4 border-transparent py-2 pl-3 pr-4 font-medium`,
                                            (false && isInverted) ? 'text-white hover:text-gray-100' : 'text-black hover:text-gray-400'
                                        )
                                    }
                                >
                    <span className={'uppercase'}>
                            Technologies
                        </span>
                                </a>

                                <div className={classNames(
                                    "relative grid gap-2 sm:gap:4 px-4",
                                    isInverted ? 'bg-gray-600' : 'bg-white'
                                )}>
                                    <FlyoutLinksMobile items={props.item.itemscol2} isInverted={isInverted}/>
                                </div>
                            </div>
                            <div className={'col-span-3 mt-5 md:mt-0'}>
                                <a

                                    className={
                                        classNames(
                                            `block text-lg uppercase border-l-4 border-transparent py-2 pl-3 pr-4 font-medium`,
                                            (false && isInverted) ? 'text-white hover:text-gray-100' : 'text-black hover:text-gray-400'
                                        )
                                    }
                                >
                                    <span className={'uppercase'}>
                                            More
                                        </span>
                                </a>

                                <div className={classNames(
                                    "relative grid gap-2 sm:gap:4 px-4",
                                    isInverted ? 'bg-gray-600' : 'bg-white'
                                )}>
                                    <FlyoutLinksMobile items={props.item.itemscol3} isInverted={isInverted}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            {/*)}*/}
        </div>
    )
}

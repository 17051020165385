import Image from 'next/image'
import Link from "next/link";
import {addSlashIfNotAbsoluteUrl} from "../../../utils/Helpers";
import StarIcon from "../../partials/StarIcon";

const navigation = {
    social: [
        {
            name: 'LinkedIn',
            href: 'https://www.linkedin.com/company/81705120',
            icon: (props: any) => (
                <svg className={'w-5 h-5 hover:opacity-60'} role="img" viewBox="0 0 24 24"
                     xmlns="http://www.w3.org/2000/svg"><title>LinkedIn</title>
                    <path
                        d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"/>
                </svg>
            ),
        },

    ],
}

interface NavigationItem {
    name: string,
    link: any,
    icon: string
}

export default function Footer(props: any) {
    const footer = props.footer;
    const [col1, col2, col3, col4] = [footer.content.column1, footer.content.column2, footer.content.column3, footer.content.column4];

    const columns = [
        {
            title: footer.content.column1_title,
            colData: col1,
        },
        {
            title: footer.content.column2_title,
            colData: col2,
        },
        {
            title: footer.content.column3_title,
            colData: col3,
        },
        {
            title: footer.content.column4_title,
            colData: col4,
        }
    ];

    return (
        <footer className="bg-white pt-32 relative" aria-labelledby="footer-heading">
            <h2 id="footer-heading" className="sr-only">
                Footer
            </h2>
            <div className="mx-auto max-w-screen-2xl py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                <div className="md:grid md:grid-cols-3 md:gap-4 xl:gap-8">
                    <div className=" xl:col-span-1 text-xs">
                        {/*<Image*/}
                        {/*    className="block h-12 w-auto"*/}
                        {/*    src={require("../../../public/images/pacifico.svg")}*/}
                        {/*    alt="Pacific Codeline LLC Logo"*/}
                        {/*/>*/}
                        <p className="text-base text-gray-400 mb-5">
                            {footer.content.slogan}
                        </p>
                        <p className="text-base text-gray-400 mb-5">
                            Pacific Codeline LLC <br/>
                            18952 MacArthur Blvd. <br/>
                            Suite 100-209 <br/>
                            Irvine, CA 92612 <br/>
                        </p>
                        <div className="flex space-x-6 mb-5">
                            {navigation.social.map((item) => (
                                <a key={item.name} href={item.href} target={"_blank"} rel={"noreferrer"} className="text-gray-400 hover:text-gray-500">
                                    <span className="sr-only">{item.name}</span>
                                    <item.icon className="h-6 w-6" aria-hidden="true"/>
                                </a>
                            ))}
                        </div>

                        <div>
                            <a
                                className={'flex gap-1 -mb-2'}
                                rel={"noreferrer"}
                                href="https://www.google.com/search?q=pacific+codeline+llc&oq=pacific+codeline+llc&gs_lcrp=EgZjaHJvbWUyBggAEEUYOTIGCAEQRRg9MgYIAhBFGDwyBggDEEUYPdIBCDE2NjNqMGo5qAIAsAIB&sourceid=chrome&ie=UTF-8#lrd=0x80c2bb85f866a8d5:0x14d736fc87d42d25,1,,,,"
                                target={"_blank"}>
                                Rated <StarIcon/> 5.0 Stars on Google Reviews
                            </a> <br/>
                            <a
                                className={'flex gap-1 -mt-3'}
                                rel={"noreferrer"}
                                href="https://clutch.co/profile/pacific-codeline#highlights"
                                target={"_blank"}>
                                Rated <StarIcon/> 5.0 Stars on Clutch.co
                            </a> <br/>
                        </div>
                        {!props.isLandingPage &&
                            <div className={'flex gap-4'}>
                                <div className={'relative aspect-square w-32'}>
                                    <a
                                        rel={"noreferrer"}
                                        className={'flex gap-1'}
                                        href="https://clutch.co/profile/pacific-codeline#highlights"
                                        target={"_blank"}>
                                        <Image loading={'lazy'} src={'/images/clutch1.png'} alt={'Award'}
                                               layout={'fill'}
                                               className={'object-contain'}/>
                                    </a>
                                </div>
                                <div className={'relative aspect-square w-32'}>
                                    <a
                                        rel={"noreferrer"}
                                        className={'flex gap-1'}
                                        href="https://clutch.co/profile/pacific-codeline#highlights"
                                        target={"_blank"}>
                                        <Image loading={'lazy'} src={'/images/clutch3.png'} alt={'Award'}
                                               layout={'fill'}
                                               className={'object-contain'}/>
                                    </a>
                                </div>
                            </div>
                        }
                    </div>
                    {!props.isLandingPage &&
                        <div className="mt-8 md:mt-12 gap-8 md:col-span-2 xl:mt-0">
                            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-4 md:gap-8">
                                {columns.map((column, index) => (
                                    <div key={index} className={'col-span-1'}>
                                        <h3 className="text-base font-medium text-gray-900">
                                            {column.title}
                                        </h3>
                                        <ul role="list" className="mt-4 space-y-4">
                                            {column.colData.map((item: NavigationItem) => (
                                                <li key={item.name}>
                                                    <Link href={addSlashIfNotAbsoluteUrl(item.link.cached_url)}
                                                          className="text-base text-gray-500 hover:text-gray-900 flex items-center">
                                                        {item.icon &&
                                                            <span
                                                                className={"mr-1"}
                                                                dangerouslySetInnerHTML={{
                                                                    __html: (item.icon)
                                                                }}
                                                            />}
                                                        {item.name}
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                ))}

                            </div>
                        </div>
                    }

                    {props.isLandingPage &&
                        <div className={'mt-8 md:mt-12 gap-8 md:col-span-2 xl:mt-0 flex -order-1'}>
                            <Image
                                width={1728}
                                height={749}
                                loading={'eager'}
                                className="block h-16 w-auto"
                                src="/images/SVG/logo.svg"
                                alt="Pacific Codeline LLC Logo"
                            />
                        </div>
                    }
                </div>

                <div className="mt-12 border-t border-gray-200 pt-8">
                    <p className="text-base text-gray-400 xl:text-center">&copy; {new Date().getFullYear()} Pacific
                        Codeline LLC. All rights reserved.</p>
                </div>
            </div>
        </footer>
    )
}
